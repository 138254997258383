import { Box, Button, useBreakpointValue } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import Countdown from './components/Countdown';

function EventTimer() {
  const [eventType, setEventType] = useState<'Gamejam' | 'Hackathon'>('Gamejam');
  const eventEndDate = new Date(
    eventType === 'Gamejam'
      ? `${process.env.REACT_APP_GAMEJAM_END_DATE}`
      : `${process.env.REACT_APP_HACKATHON_END_DATE}`,
  );

  const containerRef = useRef<HTMLDivElement>(null);
  const isDesktop = useBreakpointValue([false, true, true, true]);

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      containerRef.current?.requestFullscreen();
    }
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'F' || event.key === 'f') {
        toggleFullScreen();
      }
    };

    if (isDesktop) {
      window.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      if (isDesktop) {
        window.removeEventListener('keydown', handleKeyDown);
      }
    };
  }, [isDesktop]);

  return (
    <>
      <Box ref={containerRef} position="relative" w="100vw" h="calc(100vh - 80px)">
        <Countdown targetDate={eventEndDate} competition={eventType} />
      </Box>
      <Button
        fontSize={['.9rem', '1rem', '1rem', '1.2rem']}
        width="fit-content"
        margin="25px auto 25px auto"
        padding=".5rem 1.5rem"
        onClick={() => setEventType(eventType === 'Gamejam' ? 'Hackathon' : 'Gamejam')}
        border="1px solid white"
        zIndex={100}
      >
        Switch to {eventType === 'Gamejam' ? 'Hackathon' : 'Gamejam'}
      </Button>
    </>
  );
}

export default EventTimer;
