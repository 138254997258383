import React, { useEffect, useState } from 'react';
import { Box, ChakraProvider, Flex } from '@chakra-ui/react';
import { Route, Routes, BrowserRouter, Navigate, useLocation } from 'react-router-dom';
import MyProfile from './pages/newProfile/MyProfile';
import CompleteLoginInfo from './pages/newProfile/CompleteLoginInfo';
import ProfileService from './pages/newProfile/services/ProfileService';
import { QueryClient, QueryClientProvider } from 'react-query';
import Navbar from './common/components/Navbar/Navbar';
import Footer from './common/components/Footer/Footer';
import { NotFound } from './pages/NotFound';
import theme from './common/styles/CustomTheme';
import Fonts from './common/styles/fonts';
import { useAuth } from 'react-oidc-context';
import './App.css';
import { pageToUrl, AdminPage } from 'admin_lsac';
import { pages } from './pages/AdminPanel/schema.config';
import Map from './pages/Map/Map';
import Landing from './pages/Landing/Landing';
import EventTimer from './pages/EventTimer/EventTimer';

const queryClient = new QueryClient();

interface AppRoutesProps {
  isProfileComplete: boolean | null;
}

// Routes that get redirected to Complete Profile if the user has not completed their profile
const dynamicRoutes = [
  {
    path: '/',
    component: Landing,
  },
  {
    path: '/profile',
    component: MyProfile,
  },
  {
    path: '/map',
    component: Map,
  },
  {
    path: '/countdown',
    component: EventTimer,
  },
  {
    path: '*',
    component: NotFound,
  },
];

const AppRoutes: React.FC<AppRoutesProps> = ({ isProfileComplete }) => {
  const location = useLocation();
  const isAdminRoute = location.pathname.startsWith('/adm_panel');

  return (
    <>
      <Navbar />
      <Box h="80px"></Box>
      {isAdminRoute && localStorage.getItem('role') === 'ADMIN' ? (
        <>
          <Routes>
            {pages.map((page, index) => (
              <Route
                key={index}
                path={`adm_panel/${pageToUrl(page)}`}
                element={<AdminPage pages={pages} selectedPage={page} basePath="/adm_panel" />}
              />
            ))}
            {pages[0] ? (
              <Route
                path={`adm_panel/*`}
                element={<AdminPage pages={pages} selectedPage={pages[0]} basePath="/adm_panel" />}
              />
            ) : null}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </>
      ) : (
        <Flex
          minHeight={'100vh'}
          flexDirection={'column'}
          overflow="hidden"
          justifyContent={'space-between'}
        >
          <Routes>
            <Route path="/complete-profile" element={<CompleteLoginInfo />} />
            {dynamicRoutes.map(({ path, component: Component }) => (
              <Route
                key={path}
                path={path}
                element={
                  isProfileComplete === false ? <Navigate to="/complete-profile" /> : <Component />
                }
              />
            ))}
          </Routes>
          <Footer />
        </Flex>
      )}
    </>
  );
};

export const App = () => {
  const auth = useAuth();
  const [isProfileComplete, setIsProfileComplete] = useState<boolean | null>(null);

  useEffect(() => {
    const checkProfileCompletion = async () => {
      if (auth.user) {
        try {
          const complete = await ProfileService.isProfileCompleted(auth.user.access_token);
          const userRole = await ProfileService.getUserRole(auth.user.access_token);
          setIsProfileComplete(complete);
          localStorage.setItem('role', userRole);
          if (userRole === 'ADMIN') {
            localStorage.setItem('access_token', auth.user.access_token);
          }
        } catch (error) {
          console.error('Error checking profile completion:', error);
        }
      }
    };

    checkProfileCompletion();
  }, [auth.user]);

  return (
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={theme}>
        <Fonts />
        <BrowserRouter>
          <AppRoutes isProfileComplete={isProfileComplete} />
        </BrowserRouter>
      </ChakraProvider>
    </QueryClientProvider>
  );
};
