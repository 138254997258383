import { VStack, HStack, Container, Image, Text, useBreakpointValue, Link } from '@chakra-ui/react';
import GradientBlur from './GradientBlur';
import { useAuth } from 'react-oidc-context';
import theme from 'src/common/styles/CustomTheme';

function Landing() {
  const showRegister = process.env.REACT_APP_REGISTER === 'true';
  const StackComponent = useBreakpointValue({ base: VStack, md: HStack }) || HStack;
  const auth = useAuth();

  return (
    <StackComponent
      width="100%"
      alignItems="center"
      gap={['10vw', '10vw', '7.5vw', '7.5vw']}
      p="5vw 10vw"
      marginTop="20px"
    >
      <GradientBlur />
      <VStack flex="5" alignItems="center" justifyContent="center">
        <Container
          width="100%"
          height="50%"
          position="relative"
          display={['flex', 'flex', 'inline-block', 'inline-block']}
          flexDirection="column"
          textAlign={['center', 'center', 'start', 'start']}
          alignItems="center"
          justifyContent="center"
          p="1rem"
        >
          <Image src="./images/logo_hack.svg" mb={['4vw', '2.5vw', '1.7vw']} />
          <Text
            fontFamily={theme.fonts.description}
            fontWeight="700"
            fontSize={['4vw', '2.5vw', '1.8vw', '1.2vw']}
            mb={['6vw', '3.5vw', '2.5vw', '2.5vw']}
            color="#ABABAB"
          >
            Ne vedem la{' '}
            <Link color="#21B283" target="_blank" href="https://maps.app.goo.gl/ARsxqE5NDzj8XCxeA">
              Floreasca Park
            </Link>{' '}
            pentru a XVI-a ediție de HackITall
          </Text>
          {/* {showRegister && !auth.isAuthenticated && (
            <Button
              display="block"
              bg="#20B182"
              width={['30vw', '18vw', '15vw', '9vw']}
              height="auto"
              whiteSpace="normal"
              fontSize={['4vw', '2.5vw', '1.8vw', '1.2vw']}
              p=".2rem 1rem"
              onClick={() => {
                void auth.signinRedirect();
              }}
            >
              Înscrie-te acum!
            </Button>
          )} */}
        </Container>
      </VStack>
      <VStack flex="5" alignItems="center" justifyContent="center">
        <VStack
          width={['100%', '100%', '75%', '75%']}
          height="50%"
          position="relative"
          alignItems="center"
          justifyContent="flex-start"
        >
          <Image zIndex="10" width="80%" aspectRatio="1/1" src="./images/mascot.svg"></Image>
          <Image
            src="./images/mascotLightLayer.svg"
            position="absolute"
            left="50%"
            bottom="-10px"
            transform="translateX(-50%)"
          ></Image>
        </VStack>
      </VStack>
    </StackComponent>
  );
}

export default Landing;
