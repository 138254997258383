import { Image, Flex, HStack, Link, Show, VStack, Button } from '@chakra-ui/react';
import theme from 'src/common/styles/CustomTheme';
import Logo from '../../assets/hackitall.png';
import MyDrawer from './Drawer';
import { useContext, useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { ProfileCompletedContext } from 'src/ProfileCompletedContext';
import MapService from 'src/pages/Map/services/MapService';
import FetchId from 'src/common/services/IdService';
import { HashLink } from 'react-router-hash-link';

// Temporary fix for getting the user data
import ProfileService from 'src/pages/newProfile/services/ProfileService';

interface NavItem {
  name: string;
  link?: string;
}

const navList: NavItem[] = [
  { name: 'Acasa', link: `/#${FetchId.Home}` },
  { name: 'Probe', link: `/#${FetchId.Events}` },
  { name: 'Sponsori', link: `/#${FetchId.Sponsors}` },
  { name: 'FAQ', link: `/#${FetchId.FAQ}` },
  { name: 'Contact', link: `/#${FetchId.Contact}` },
  { name: 'Harta', link: process.env.REACT_APP_MAP },
];

const navListLoggedIn: NavItem[] = [
  { name: 'Acasa', link: `/#${FetchId.Home}` },
  { name: 'Probe', link: `/#${FetchId.Events}` },
  { name: 'Sponsori', link: `/#${FetchId.Sponsors}` },
  { name: 'FAQ', link: `/#${FetchId.FAQ}` },
  { name: 'Contact', link: `/#${FetchId.Contact}` },
  { name: 'Profil', link: '/profile' },
  { name: 'Harta', link: process.env.REACT_APP_MAP },
];

const showRegister = process.env.REACT_APP_REGISTER === 'true';

const linkStyle = {
  textDecoration: 'none',
  fontSize: '20px',
  color: theme.colors.heading,
  fontFamily: theme.fonts.navbarFont,
  fontWeight: '400',
};

const eventStartDate = new Date(`${process.env.REACT_APP_GAMEJAM_START_DATE}`);

const hasEventStarted = () => {
  return new Date() >= eventStartDate;
};

const Navbar = () => {
  const [isLogged, setIsLogged] = useState(false);
  const auth = useAuth();
  const profileCompleted = useContext(ProfileCompletedContext);
  const [eventStarted, setEventStarted] = useState(hasEventStarted());

  useEffect(() => {
    if (auth.isLoading) return;

    // User is logged in
    setIsLogged(auth.isAuthenticated);

    const token = auth.user?.access_token ?? '';

    const fetchData = async () => {
      try {
        // Temporary fix for getting the user data
        const user = await ProfileService.getUserInfo(token);
        if (user?.role == 'ADMIN') {
          navListLoggedIn.push({ name: 'Admin', link: '/adm_panel' });
        }
      } catch (error) {
        console.error("Failed to fetch user's data: ", error);
      }

      try {
        const usersTeamInfo = await MapService.fetchTeamData(token);
        if (usersTeamInfo?.status == 'ACCEPTED') {
          navListLoggedIn.push({ name: 'Map', link: '/map' });
        }
      } catch (error) {
        console.error("Failed to fetch user's team data: ", error);
      }
    };

    if (auth.isAuthenticated) void fetchData();

    setEventStarted(hasEventStarted());
  }, [auth]);

  // Build nav links array
  const dynamicNavList = isLogged ? [...navListLoggedIn] : [...navList];
  if (eventStarted) dynamicNavList.push({ name: 'Countdown', link: '/countdown' });

  return (
    <>
      <VStack
        as={'header'}
        position={'fixed'}
        w={'100%'}
        spacing={0}
        backgroundColor={theme.colors.navbar}
        zIndex={1000}
      >
        <Flex w="100%" px="6" py="5" align="center" justify="space-between">
          <Link style={linkStyle} href="/">
            <Image src={Logo} fit="contain" maxW="auto" maxH="35px" />
          </Link>
          <Show breakpoint="(min-width: 861px)">
            <HStack as="nav" spacing={{ base: 3, lg: 7 }}>
              {profileCompleted || profileCompleted === null ? (
                dynamicNavList.map((item, index) => (
                  <HashLink
                    key={index}
                    smooth
                    to={item.link ?? ''}
                    style={linkStyle}
                    color="#DEDEDE"
                  >
                    {item.name}
                  </HashLink>
                ))
              ) : (
                <></>
              )}
              {showRegister ? (
                isLogged ? (
                  <Button
                    fontSize={{ base: '20px' }}
                    color={theme.colors.heading}
                    fontFamily={theme.fonts.navbarFont}
                    _hover={{ textDecor: 'none' }}
                    fontWeight={'400'}
                    onClick={() => {
                      void auth.removeUser().finally(() => {
                        window.location.href = '/';
                        setIsLogged(false);
                      });
                    }}
                  >
                    Deconectare
                  </Button>
                ) : (
                  <Button
                    fontSize={{ base: '20px' }}
                    color={theme.colors.heading}
                    fontFamily={theme.fonts.navbarFont}
                    _hover={{ textDecor: 'none' }}
                    fontWeight={'400'}
                    onClick={() => {
                      void auth.signinRedirect();
                    }}
                  >
                    Logare
                  </Button>
                )
              ) : (
                <></>
              )}
            </HStack>
          </Show>
          <Show breakpoint="(max-width: 860px)">
            <MyDrawer navList={dynamicNavList} linkStyle={linkStyle} />
          </Show>
        </Flex>
      </VStack>
    </>
  );
};
export default Navbar;
