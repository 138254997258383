import SponsorCard from './conponents/SponsorCard';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import { VStack, Text, Box, useBreakpointValue } from '@chakra-ui/react';
import Next from '../../common/assets/Next.png';
import Prev from '../../common/assets/Previous.png';
import { ArrowButton } from '../../common/components/Button/ArrowIconButton';
import theme from 'src/common/styles/CustomTheme';

import ING_Hubs from './assets/Sponsors2024-2025/ING.png';
import JetBrains from './assets/Sponsors2024-2025/JetBrains.svg';
import AmazonGames from './assets/Sponsors2024-2025/amazon_games.png';
import Playtika from './assets/Sponsors2024-2025/playtika.png';
import Veridion from './assets/Sponsors2024-2025/Veridion.png';
import Telekom from './assets/Sponsors2024-2025/telekom.svg';

import Best from './assets/Media_partners/Best white logo.png';
import DevMind from './assets/Media_partners/DevMind_dark_bg.jpg';
import EthLogo from './assets/Media_partners/ETH_logo.png';
import SoftServe from './assets/Media_partners/softserve_logo.png';
import Hochland from './assets/Media_partners/HochlandLogo.png';
import Cumpana from './assets/Media_partners/CUMPANACuPicatura.png';
import FloreascaPark from './assets/Media_partners/FloreascaParkLogo.png';
import Burn from './assets/Media_partners/BurnLogo.png';
import LBFC from './assets/Media_partners/LBFCLogo.svg';
import Zeina from './assets/Media_partners/ZeinaLogo.jpg';

import FetchId from 'src/common/services/IdService';
import PartnerCard from './conponents/PartnerCard';

const Partners = [
  {
    title: 'Hydration Partner',
    srcs: [Cumpana],
  },
  {
    title: 'Energy Partner',
    srcs: [Burn],
  },
  // {
  //   title: 'Refreshment Partner',
  //   srcs: [Pfanner],
  // },
  // {
  //   title: 'Pizza Partner',
  //   srcs: [Dodo],
  // },
  {
    title: 'Snack Partner',
    srcs: [Hochland],
  },
  // {
  //   title: 'Nutrition Partners',
  //   srcs: [Mega, GoldNutrition],
  // },
  {
    title: 'Food Partner',
    srcs: [Zeina, LBFC],
  },
  {
    title: 'Media Partner',
    srcs: [Best, DevMind, EthLogo],
  },
  {
    title: 'Logistics Partner',
    srcs: [FloreascaPark],
  },
];

const SponsorList = [
  {
    name: 'ING Hubs',
    sponsors: [
      {
        type: 'Hackathon',
        description:
          'ING Hubs România este hub-ul global de tehnologie al grupului ING, unde peste 1.600 de specialiști dezvoltă soluții software și servicii IT pentru entități ING din întreaga lume. Cu un mediu de lucru flexibil, programe dedicate studenților și profesioniștilor IT și proiecte de impact global, compania susține inovația și dezvoltarea talentelor din domeniul tech.',
        logo: ING_Hubs,
        link: 'https://inghubsromania.com/home',
      },
    ],
  },
  {
    name: 'Playtika',
    sponsors: [
      {
        type: 'Game Jam',
        description:
          'De la începuturile sale ca startup israelian în 2010, Playtika a crescut pentru a deveni un lider în industrie, cu milioane de utilizatori activi zilnic în portofoliul său de jocuri. Playtika stăpânește operarea și scalarea jocurilor, folosind cele mai noi tehnologii pentru a dezvolta titluri care captivează și inspiră explorarea, conectivitatea și distracția.',
        logo: Playtika,
        link: 'https://www.playtika.com/ ',
      },
    ],
  },
  {
    name: 'JetBrains',
    sponsors: [
      {
        type: 'Hackathon',
        description:
          'JetBrains este o companie renumită pentru crearea celor mai apreciate instrumente de dezvoltare software, precum IntelliJ IDEA, PyCharm, și multe altele. JetBrains oferă soluții care ajută dezvoltatorii să își optimizeze fluxul de muncă, să scrie cod mai eficient și să creeze software de calitate.',
        logo: JetBrains,
        link: 'https://www.jetbrains.com/',
      },
    ],
  },
  {
    name: 'Amazon Games:',
    sponsors: [
      {
        type: 'Game Jam',
        description:
          'Amazon Games este divizia de gaming a gigantului Amazon, dedicată creării de jocuri inovative care oferă experiențe captivante și imersive pentru milioane de jucători din întreaga lume. Prin tehnologie de ultimă oră și o viziune creativă, Amazon Games redefineste modul în care interacționăm cu jocurile și produsele de entertainment digital.',
        logo: AmazonGames,
        link: 'https://www.amazongames.com/en-us',
      },
    ],
  },
  {
    name: 'Veridion',
    sponsors: [
      {
        type: 'Side Challenge',
        description:
          'Veridion este un startup deeptech care construiește sursa unică de adevăr pentru datele despre companii din toate industriile. Procesând miliarde de site-uri web și petabytes de date în fiecare săptămână, obține o înțelegere profundă a ceea ce definește o afacere.',
        logo: Veridion,
        link: 'https://veridion.com/',
      },
    ],
  },
  {
    name: 'Softserve',
    sponsors: [
      {
        type: '',
        description:
          'SoftServe este o companie IT globală, cu peste 30 de ani de istorie. Având peste 10,000 de oameni în 16 țări, expertiza companiei este variată - de la Big Data, AI și ML la inginerie software, Cloud DevOps și multe altele. Prezentă de 3 ani pe piața din România, SoftServe are o echipă de peste 100 de experți pe plan local.',
        logo: SoftServe,
        link: 'https://www.softserveinc.com/en-us',
      },
    ],
  },
  {
    name: 'Telekom',
    sponsors: [
      {
        type: 'Recruiter',
        description:
          'Telekom Romania Mobile Communications S.A. este compania de telefonie mobilă ce operează în România sub marca Telekom, având peste 3 milioane de clienți la 31 decembrie 2024. În 2024, Telekom Romania Mobile a fost partener exclusiv de telecomunicații pentru marile festivaluri Neversea și Untold, demonstrând capacitatea de desfășurare rapidă a unei rețele puternice, fiabile și adaptată cerințelor unor evenimente de zeci de mii de participanți. Telekom Romania Mobile oferă servicii 5G în București și alte 13 orașe din țară, iar serviciile de voce sunt disponibile în ariile cu acoperire 2G si 4G/5G prin tehnologia VoLTE.',
        logo: Telekom,
        link: 'https://mobile.telekom.ro/',
      },
    ],
  },
];

interface Partner {
  title: string;
  srcs: string[];
}

interface Sponsor {
  type: string;
  description: string;
  logo: string;
  link: string;
}

interface Event {
  name: string;
  sponsors: Sponsor[];
}

const Sponsors = () => {
  const sponsorCards = SponsorList.map((event: Event) => {
    return event.sponsors.map((sponsor: Sponsor) => {
      return (
        <SponsorCard
          key={sponsor.type}
          type={sponsor.type}
          event={event.name}
          description={sponsor.description}
          logo={sponsor.logo}
          link={sponsor.link}
        />
      );
    });
  });

  const partnerCards = Partners.flatMap((partner: Partner) => {
    return partner.srcs.map((src: string) => (
      <PartnerCard
        key={`${partner.title}-${src}`} // Ensure unique keys
        title={partner.title}
        logo={src}
      />
    ));
  });

  const settings = {
    infinite: true,
    slidesToShow: useBreakpointValue({ base: 1, md: 2, lg: 3 }),
    slidesToScroll: useBreakpointValue({ base: 1, md: 2, lg: 3 }),
    speed: 1000,

    autoplay: true,
    autoplaySpeed: 4000,
    cssEase: 'ease-in-out',
    pauseOnHover: true,

    nextArrow: <ArrowButton type="right" src={Next} />,
    prevArrow: <ArrowButton type="left" src={Prev} />,
  };

  const boxHeight = useBreakpointValue({ base: '30vh', md: '40vh', lg: '70vh' });
  const minBoxHeight = useBreakpointValue({ base: '90vw', md: '40vw', lg: '0vw' });

  return (
    <VStack id={FetchId.Sponsors}>
      <Text
        fontFamily={theme.fonts.sectionTitle}
        fontSize={['8vw', '4vw']}
        mb="2%"
        mt="5%"
        alignSelf={{ base: 'justify', md: 'flex-start' }}
        pl={{ base: '0', md: '15%' }}
      >
        Sponsori
      </Text>
      <Box width="80vw" h={boxHeight} minH={minBoxHeight}>
        <Slider {...settings}>
          {sponsorCards.map((sponsorCard, index) => (
            <Box key={index} p={'8%'}>
              {sponsorCard}
            </Box>
          ))}
        </Slider>
      </Box>
      {
        <Box width="80vw" h={boxHeight} minH={minBoxHeight}>
          <Slider {...settings}>
            {partnerCards.map((sponsorCard, index) => (
              <Box key={index} p={'8%'}>
                {sponsorCard}
              </Box>
            ))}
          </Slider>
        </Box>
      }
    </VStack>
  );
};

export default Sponsors;
