import React, { useEffect, useRef, useState } from 'react';
import { BiFullscreen } from 'react-icons/bi';
import { Text, VStack, HStack, Box, useBreakpointValue, calc } from '@chakra-ui/react';
import { CircularProgress } from '@chakra-ui/react';
import theme from 'src/common/styles/CustomTheme';

interface CountdownProps {
  targetDate: Date;
  competition: string;
}

interface CountdownTime {
  ore: number;
  minute: number;
  secunde: number;
}

const Countdown: React.FC<CountdownProps> = ({ targetDate, competition }) => {
  const [countdownTime, setCountdownTime] = useState<CountdownTime>(updateCountdown());

  function updateCountdown(): CountdownTime {
    let updatedCountdown: CountdownTime = {
      ore: 0,
      minute: 0,
      secunde: 0,
    };

    const countdownTimeLeft = targetDate.getTime() - new Date().getTime();
    if (countdownTimeLeft > 0) {
      updatedCountdown = {
        ore: Math.floor(countdownTimeLeft / (1000 * 60 * 60)),
        minute: Math.floor((countdownTimeLeft / (1000 * 60)) % 60),
        secunde: Math.floor((countdownTimeLeft / 1000) % 60),
      };
    }

    return updatedCountdown;
  }

  useEffect(() => {
    const countdownTimer = setInterval(() => {
      setCountdownTime(updateCountdown());
    }, 1000);

    return () => clearInterval(countdownTimer);
  }, [targetDate]);

  const countdownUnitFormat = (value: number) => (value < 10 ? `0${value}` : value);

  const visibleUnits = useBreakpointValue({
    base: ['ore', 'minute', 'secunde'],
    md: ['ore', 'minute', 'secunde'],
  });

  const progressBarThickness = useBreakpointValue({
    base: '4px',
    sm: '5px',
    md: '5px',
    lg: '6px',
  });

  const containerRef = useRef<HTMLDivElement>(null);
  const [isFullscreen, setIsFullscreen] = useState(false);

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      containerRef.current?.requestFullscreen();
      setIsFullscreen(true);
    } else {
      document.exitFullscreen();
      setIsFullscreen(false);
    }
  };

  const handleTouch = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
      setIsFullscreen(false);
    }
  };

  useEffect(() => {
    window.addEventListener('touchstart', handleTouch);

    return () => {
      window.removeEventListener('touchstart', handleTouch);
    };
  }, []);

  return (
    <VStack
      ref={containerRef}
      position="relative"
      height="100%"
      width="100vw"
      display="flex"
      flexDirection={isFullscreen ? 'row' : 'column'}
      alignItems="center"
      justifyContent="center"
      transform={isFullscreen ? 'rotate(90deg)' : 'none'}
      fontFamily={theme.fonts.countdown}
    >
      <svg width="0" height="0">
        <defs>
          <linearGradient id="progressGradient" x1="100%" y1="100%" x2="0%" y2="0%">
            <stop offset="0%" stopColor="rgba(32, 177, 130, 1)" />
            <stop offset="60%" stopColor="rgb(90, 200, 160)" />
            <stop offset="80%" stopColor="rgb(160, 230, 200)" />
            <stop offset="90%" stopColor="rgb(255, 255, 255)" />
          </linearGradient>
        </defs>
      </svg>
      <Text
        position={isFullscreen ? 'absolute' : 'relative'}
        right={isFullscreen ? 'calc(25% - 20vw)' : 'none'}
        width={['50%', '50%', '50%', '35%']}
        textAlign="center"
        fontSize={['3.2vw', '3vw', '2.5vw', '2vw']}
        fontWeight="bold"
        transform={isFullscreen ? 'rotate(90deg)' : 'none'}
      >
        Au mai rămas
      </Text>
      <HStack
        width="100%"
        alignItems="center"
        justifyContent="center"
        transform={isFullscreen ? 'rotate(90deg)' : 'none'}
        gap={isFullscreen ? '15%' : '1%'}
        marginLeft={['5vw', '5vw', '3.5vw', '3.5vw']}
        marginRight={['5vw', '5vw', '3.5vw', '3.5vw']}
        marginTop={['2.5vw', '2.5vw', '1.75vw', '1.75vw']}
        marginBottom={['2.5vw', '2.5vw', '1.75vw', '1.75vw']}
      >
        {Object.entries(countdownTime)
          .filter(([label]) => visibleUnits?.includes(label))
          .map(([label, value]) => {
            const maxValue = label == 'ore' ? 48 : 60;
            const progress = 100 - (value / maxValue) * 100;

            let labelText = label;

            if (label == 'secunde' && value == 1) labelText = 'secunda';
            if (label == 'minute' && value == 1) labelText = 'minut';
            if (label == 'ore' && value == 1) labelText = 'ora';

            return (
              <VStack
                key={label}
                position="relative"
                width={['25%', '25%', '21%', '18%']}
                aspectRatio="1/1"
                alignContent="center"
                justifyContent="center"
                spacing="0"
                borderRadius="50%"
                borderColor="rgba(255, 255, 255, 0.1)"
              >
                <Text lineHeight="1.1" fontWeight="bold" fontSize={['5vw', '5vw', '3.5vw', '3vw']}>
                  {countdownUnitFormat(value)}
                </Text>
                <Text lineHeight="1.1" fontSize={['3vw', '3vw', '2vw', '1.5vw']}>
                  {labelText}
                </Text>
                <CircularProgress
                  position="absolute"
                  top="50%"
                  left="50%"
                  transform="translate(-50%, -50%)"
                  width={isFullscreen ? '150%' : '100%'}
                  size="100%"
                  thickness={progressBarThickness}
                  trackColor="rgba(255, 255, 255, 0.1)"
                  color={'url(#progressGradient)'}
                  capIsRound
                  value={progress}
                ></CircularProgress>
              </VStack>
            );
          })}
      </HStack>
      <Text
        position={isFullscreen ? 'absolute' : 'relative'}
        left={isFullscreen ? 'calc(25% - 20vw)' : 'none'}
        width={['50%', '50%', '50%', '35%']}
        textAlign="center"
        fontSize={['3.2vw', '3vw', '2.5vw', '2vw']}
        fontWeight="bold"
        lineHeight="1.2"
        transform={isFullscreen ? 'rotate(90deg)' : 'none'}
      >
        Până la sfârșitul competiției de{' '}
        <Text display="inline-block" color="rgba(32, 177, 130, 1)">
          {competition}!
        </Text>
      </Text>

      {!isFullscreen && (
        <Box
          as="button"
          onClick={toggleFullScreen}
          position="absolute"
          bottom="1.5rem"
          right="1.5rem"
          zIndex="10"
          display={['block', 'none', 'none', 'none']}
          background="transparent"
          border="none"
        >
          {isFullscreen ? '' : <BiFullscreen size="36" />}
        </Box>
      )}
    </VStack>
  );
};

export default Countdown;
